import { Link, navigate } from "gatsby";
import * as React from "react";

import Layout from "../components/layout";
const RESUME_URL =
  "https://drive.google.com/file/d/1UakYlS-pP2T-fgpZrfkoEoailTOnm4g3/view?usp=sharing";

const Resume = ({ location }) => {
  const siteTitle = `Resume`;

  if (typeof window !== "undefined") navigate(RESUME_URL);

  return (
    <Layout location={location} title={siteTitle}>
      <h1 className="main-heading">Resume</h1>
      <p style={{ marginTop: "calc(var(--spacing-8) / 2)" }}>
        This page should redirect to my resume. If it doesn't,{" "}
        <Link to={RESUME_URL}>click here</Link>.
      </p>
    </Layout>
  );
};

export default Resume;
